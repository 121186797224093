import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';


export const _DEBUG = !environment.production;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angularSupertokensTestApp';

  public vh: any;

  ngOnInit(): void {
    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    localStorage.clear();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.vh = window.innerHeight * 0.01;
    if (_DEBUG) console.log(window.innerHeight)
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
  }

  /*** 
   * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   * ***/
}
