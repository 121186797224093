import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome', loadComponent: () => import('./welcome/welcome.component').then(c => c.WelcomeComponent)
  },
  {
    path: 'register', loadComponent: () => import('./register/register.component').then(c => c.RegisterComponent)
  },
  {
    path: 'personality-check', loadComponent: () => import('./personality-check/personality-check.component').then(c => c.PersonalityCheckComponent)
  },
  {
    path: 'check-result', loadComponent: () => import('./check-result/check-result.component').then(c => c.CheckResultComponent)
  },
  {
    path: 'take-picture', loadComponent: () => import('./take-picture/take-picture.component').then(c => c.TakePictureComponent)
  },
  {
    path: 'greetings', loadComponent: () => import('./greetings/greetings.component').then(c => c.GreetingsComponent)
  }

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
