export const environment = {
  production: false,
  API: "https://nestjstestapi.devel.aitribe.it/",
  API_URL: "",
  IMAGES: "",
  FILES: "",
  MESSAGES: {
    SUCCESS: "",
    ERROR: "",
    WARNING: "",
    INFO: ""
  },
  APP_LOGO: "/assets/logo.png",
  SUPERTOKENS: {
    ENABLE_DEBUG_MODE: true,
    APP_INFO: {
      APP_NAME: "Angular Supertokens Test App",
      API_DOMAIN: "https://nestjstestapi.devel.aitribe.it",
      API_BASE_PATH: "auth",
      WEBSITE_DOMAIN: "https://mobiletest.devel.aitribe.it",
      WEBSITE_BASE_PATH: "auth",
    },
    CLIENT_TYPE: "aitribe-web-test",
    RECIPE_LIST: {
      REDIRECT: {
        NEW_USER: "/home",
        EXISTING_USER: "/home",
        DEFAULT: "/home"
      }
    },
    STYLE: {
      PALETTE_PRIMARY: "210, 210, 210",
      PALETTE_PRIMARY_BORDER: "114, 113, 113",
    },
    LANGUAGE: {
      DEFAULT: "it",
    }
  }
};